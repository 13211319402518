// Redirection if not logged-in -------------------------------------------------
// Here should use an auth API to validate JWT from the backend...
if (localStorage.getItem("token")) {
    console.log("Auth OK");
} else {
    window.location.href = "./login.html";
}

// Log out ----------------------------------------------------------------------
// Log-out btn has a onclick-inline-js.
function logOut() {
    localStorage.clear();                   // Delete token and user configs.
    window.location.href = "./login.html";
}